import Block from "@/components/wrappers/block"
import Blocks from "@/components/Blocks"
import styles from "./number-focus.module.css"
import classNames from "classnames"

export default function NumberFocus({ block }) {
  const { innerBlocks } = block
  const blockCount = innerBlocks.length

  if (blockCount === 0) {
    return null
  }

  const settings = {
    "theme/number-focus-number": { siblings: blockCount }
  }

  return (
    <Block
      block={block}
      className={classNames(
        styles.wrapper,
        "numbers-" + Math.min(3, blockCount)
      )}>
      <Blocks blocks={innerBlocks} settings={settings} />
    </Block>
  )
}
